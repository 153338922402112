/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Item } from "@react-stately/collections";

import { Box } from "~src/designSystem/atoms/Box";
import { ListBoxItem } from "~src/designSystem/atoms/ListBoxItem";
import { Text } from "~src/designSystem/atoms/Text";
import { Tooltip } from "~src/designSystem/atoms/Tooltip";
import {
  OldHeadingUseTextInstead,
  OldSubheadingUseTextInstead,
} from "~src/designSystem/deprecated/Heading";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { RectangularLoader } from "~src/designSystem/loading/RectangularLoader";
import { Select } from "~src/designSystem/molecules/Select";
import { isNotUndefined } from "~src/shared/helpers/booleanCoercion";
import { formatCents } from "~src/shared/helpers/format";
import { randomInt } from "~src/shared/helpers/random";
import { useNotNullContext } from "~src/shared/helpers/useNotNullContext";
import { ProposalsContext, useProposalStore } from "~src/vendor/inbox/contexts/ProposalsContext";
import { useSupportedCurrency } from "~src/vendor/utils/useSupportedCurrencies";

import { useCoreMetricsQueryByPublicID } from "./__generated__/CoreMetrics";

interface IProps {
  vendorPublicIDOverride?: string;
}

const LoadingMetric: React.FC = () => {
  return (
    <Box
      height="23px"
      css={css`
        padding-top: 6px;
      `}
    >
      <RectangularLoader height={10} borderRadius={6} width={randomInt(75, 95)} />
    </Box>
  );
};

export const CoreMetrics: React.FC<IProps> = (props) => {
  const collectionPeriodFilter = useProposalStore((s) => s.collectionPeriodFilter);
  const collectionPeriods = useProposalStore((s) => s.collectionPeriods);
  const setCollectionPeriodFilter = useProposalStore((s) => s.setCollectionPeriodFilter);
  const showCollectionPeriodFilter = useProposalStore((s) => s.showCollectionPeriodFilter);
  const { checkable } = useNotNullContext(ProposalsContext);

  const currency = useSupportedCurrency();

  const { data, loading } = useCoreMetricsQueryByPublicID({
    variables: {
      public_id: props.vendorPublicIDOverride ?? null,
    },
  });
  const vendor = data?.vendors[0];

  const totalARR = vendor?.vendor_metrics[0]?.total_addressable_arr;

  // We take the max to ensure remainingTradeLimit is never negative.
  let remainingTradeLimit;
  if (isNotUndefined(vendor)) {
    remainingTradeLimit = Math.max(
      0,
      vendor.credit_limit - (vendor.payment_status?.credit_usage ?? 0),
    );
  }

  return (
    <Flexbox
      row
      css={css`
        position: relative;
      `}
    >
      <Ticker>
        <Tooltip
          tooltipContent={
            <Text>
              <b>Available ARR</b> is the total ARR synced to Pipe that can be traded.
            </Text>
          }
        >
          <OldSubheadingUseTextInstead size="11px">Available ARR</OldSubheadingUseTextInstead>
        </Tooltip>
        <OldHeadingUseTextInstead size="13px">
          {loading ? <LoadingMetric /> : formatCents(totalARR ?? 0, currency, { omitCents: false })}
        </OldHeadingUseTextInstead>
      </Ticker>
      <Ticker>
        <Tooltip
          tooltipContent={
            <Text>
              <b>Remaining Trade Limit</b> is the remaining volume of contracts you can currently
              trade on Pipe.
            </Text>
          }
        >
          <OldSubheadingUseTextInstead size="11px">
            Remaining Trade Limit
          </OldSubheadingUseTextInstead>
        </Tooltip>
        <OldHeadingUseTextInstead size="13px">
          {remainingTradeLimit === undefined ? (
            <LoadingMetric />
          ) : (
            formatCents(remainingTradeLimit, currency, {
              omitCents: false,
            })
          )}
        </OldHeadingUseTextInstead>
      </Ticker>
      {showCollectionPeriodFilter && (
        <Ticker>
          <Tooltip
            tooltipContent={
              <Text>
                <b>Filter by Collection Period</b> allows you to filter contracts based on the
                period at which their payments are collected by Pipe.
              </Text>
            }
          >
            <OldSubheadingUseTextInstead size="11px">
              Filter by Collection Period
            </OldSubheadingUseTextInstead>
          </Tooltip>
          <OldHeadingUseTextInstead size="12px">
            <Select
              name="termFilter"
              label="termFilter"
              showSelectLabel={false}
              size="small"
              popoverWidth="126px"
              selectedKey={String(collectionPeriodFilter)}
              onSelectionChange={(key: string) => {
                checkable.reset();
                setCollectionPeriodFilter(Number(key));
              }}
            >
              {[
                <Item key="-1" textValue="All">
                  <ListBoxItem label="All" />
                </Item>,
              ].concat(
                collectionPeriods.map((period) => {
                  const label = `${period} month${period === 1 ? "" : "s"}`;
                  return (
                    <Item key={String(period)} textValue={label}>
                      <ListBoxItem label={label} />
                    </Item>
                  );
                }),
              )}
            </Select>
          </OldHeadingUseTextInstead>
        </Ticker>
      )}
    </Flexbox>
  );
};

const Ticker = styled(Flexbox)`
  min-width: 100px;
  margin-right: 36px;
`;

graphql`
  query CoreMetricsQueryByPublicID($public_id: String) {
    vendors(where: { public_id: { _eq: $public_id } }) {
      public_id
      credit_limit
      payment_status {
        credit_usage
        total_payout
      }
      rate_months_1
      max_contract_term_months
      vendor_metrics(order_by: { timestamp: desc }, limit: 1) {
        total_addressable_arr
      }
    }
  }
`;
